@import 'src/styles/variables.module.scss';

.inputtext {
  padding: 17px 20px;
  font-size: 14px;
  border-radius: 4px;
  border: 2px solid $daseruno-light-gray;
  box-sizing: border-box;
  color: $daseruno-gray2;
  position: relative;

  &_login {
    @extend .inputtext;
    padding: 17px 20px;
    font-size: 20px;
    border-radius: 8px;
    background: $daseruno-light-orange2;
  }

  &_right {
    @extend .inputtext;
    text-align: right;
  }

  &_error {
    @extend .inputtext;
    border: 2px solid $daseruno-red;

    &_text {
      position: absolute;
      color: $daseruno-red;
      font-weight: 600;
      font-size: 14px;
      transform: translateY(-20%);
    }
  }

  &_visible_icon {
    position: absolute;

    &_semilarge {
      @extend .inputtext_visible_icon;
      transform: translateX(1700%) translateY(-180%);
    }

    &_xlarge {
      @extend .inputtext_visible_icon;
      transform: translateX(2100%) translateY(-250%);
    }
  }

  &_read_only {
    background: rgba(244, 164, 96, 0.2);
    outline: none;
  }

  &::placeholder {
    color: $daseruno-gray2;
    opacity: 0.5;
  }

  // size
  &_full {
    @extend .inputtext;
    width: 938px;
  }

  &_xlarge {
    @extend .inputtext;
    width: 476px;
    height: 85px;
  }

  &_xlarge2 {
    @extend .inputtext;
    width: 600px;
  }

  &_large {
    @extend .inputtext;
    width: 430px;
  }

  &_semilarge {
    @extend .inputtext;
    width: 384px;
  }

  &_middle {
    @extend .inputtext;
    width: 308px;
  }

  &_semimiddle {
    @extend .inputtext;
    width: 240px;
  }

  &_semimiddle2 {
    @extend .inputtext;
    width: 191px;
  }

  &_semimiddle_detail_row {
    @extend .inputtext;
    font-size: xx-small;
    padding: 2px 2px;
    width: 240px;
    height: 36px;
  }

  &_small {
    @extend .inputtext;
    width: 153px;
  }

  &_xsmall {
    @extend .inputtext;
    width: 140px;
  }

  &_xsmall_detail_row {
    @extend .inputtext;
    font-size: xx-small;
    padding: 2px 2px;
    width: 112px;
    height: 36px;
  }

  &_minimum {
    @extend .inputtext;
    padding: 6px 8px;
    width: 64px;
    height: 54px;
  }

  &_minimum_detail_row {
    @extend .inputtext;
    font-size: xx-small;
    padding: 2px 2px;
    width: 42px;
    height: 36px;
  }

  &_in_table {
    @extend .inputtext;
    width: 90px;
    height: 36px;
  }

  &_detail_weight_in_use {
    @extend .inputtext;
    padding: 8px 10px;
    width: 64px;
    height: 36px;
  }

  &_cost_simulation {
    @extend .inputtext;
    width: 120px;
    height: 64px;
  }
}

.textarea {
  padding: 17px 20px;
  font-size: 14px;
  border-radius: 4px;
  border: 2px solid $daseruno-light-gray;
  box-sizing: border-box;
  color: $daseruno-gray2;
  position: relative;
  &::placeholder {
    color: $daseruno-gray2;
    opacity: 0.5;
  }

  &_error {
    @extend .textarea;
    border: 2px solid $daseruno-red;

    &_text {
      position: absolute;
      color: $daseruno-red;
      font-weight: 600;
      font-size: 14px;
      transform: translateY(-20%);
    }
  }

  // size
  &_full {
    @extend .inputtext;
    width: 940px;
  }

  &_large {
    @extend .inputtext;
    width: 430px;
  }
}

.selectbox {
  padding: 17px 20px;
  font-size: 14px;
  border-radius: 4px;
  border: 2px solid $daseruno-light-gray;
  box-sizing: border-box;
  color: $daseruno-gray2;
  appearance: none;
  background-image: url('../../images/Form/select-box-arrow.png');
  background-position: right 10px center;
  background-repeat: no-repeat;
  background-size: 17px 38px;

  &_error {
    @extend .textarea;
    border: 2px solid $daseruno-red;

    &_text {
      position: absolute;
      color: $daseruno-red;
      font-weight: 600;
      font-size: 14px;
      transform: translateY(-20%);
    }
  }

  // color
  &_black {
    color: $black;
  }

  &_gray {
    color: $daseruno-light-gray;
  }

  // size
  &_large {
    @extend .selectbox;
    width: 430px;
  }

  &_large2 {
    @extend .selectbox;
    width: 455px;
  }

  &_xlarge {
    @extend .selectbox;
    width: 670px;
  }

  &_middle {
    @extend .selectbox;
    width: 348px;
  }

  &_small {
    @extend .selectbox;
    width: 120px;
  }

  &_display_none {
    @extend .selectbox;
    display: none;
  }
}

.checkbox {
  display: flex;
  align-items: center;

  &_center {
    @extend .checkbox;
    justify-content: center;
  }

  &_style {
    width: 32px;
    height: 32px;
    border-radius: 4px;

    &_default {
      @extend .checkbox_style;
      border: 2px solid $daseruno-light-gray;
    }

    &_read_only {
      @extend .checkbox_style;
      background: $daseruno-light-gray;
      border: 2px solid $daseruno-light-gray;
    }

    &_checked {
      @extend .checkbox_style;
      border: 2px solid $daseruno-red;
      background: $daseruno-red;
    }

    &_table_header {
      @extend .checkbox_style;
      width: 16px;
      height: 16px;
      border: 1px solid $daseruno-gray2;
      background: $white;

      &_checked {
        @extend .checkbox_style_table_header;
        border: 1px solid $daseruno-red;
        background: $daseruno-red;
      }

      &_read_only {
        @extend .checkbox_style_table_header;
        background: $daseruno-light-gray;
      }
    }

    &_table_body {
      @extend .checkbox_style;
      width: 24px;
      height: 24px;
      border: 2px solid $daseruno-light-gray;

      &_checked {
        @extend .checkbox_style_table_body;
        border: 2px solid $daseruno-red;
        background: $daseruno-red;
      }

      &_read_only {
        @extend .checkbox_style_table_body;
        background: $daseruno-light-gray;
      }
    }
  }

  &_mark {
    width: 16px;
    height: 8px;
    margin: 0 auto;
    display: block;
    margin-top: 8px;
    border-left: 2px solid $white;
    border-bottom: 2px solid $white;
    transform: rotate(-45deg);

    &_table_header {
      @extend .checkbox_mark;
      width: 8px;
      height: 4px;
      margin-top: 4px;
    }

    &_table_body {
      @extend .checkbox_mark;
      width: 12px;
      height: 6px;
      margin-top: 6px;
    }
  }

  &_input {
    display: none;
  }

  &_text {
    margin-left: 10px;
    font-size: 14px;
    color: $daseruno-gray2;

    &_table_header_small {
      @extend .checkbox_text;
      font-size: 12px;
    }
  }

  // size
  &_table_header_small {
    @extend .checkbox;
    width: 48px;
  }

  &_table_body_small {
    @extend .checkbox;
    width: 36px;
  }

  &_semi_small {
    @extend .checkbox;
    width: 94px;
  }

  &_small {
    @extend .checkbox;
    width: 108px;
  }

  &_semi_middle {
    @extend .checkbox;
    width: 143px;
  }

  &_middle {
    @extend .checkbox;
    width: 156px;
  }

  &_semi_large {
    @extend .checkbox;
    width: 186px;
  }

  &_large {
    @extend .checkbox;
    width: 216px;
  }

  &_xlarge {
    @extend .checkbox;
    width: 356px;
  }
}

.radioButtonContainer {
  display: flex;
  align-items: center;
}

.radioButtonStyle {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 2px solid $daseruno-light-gray;

  &_small {
    @extend .radioButtonStyle;
    width: 24px;
    height: 24px;
  }
}

.radioButtonCheckMark {
  width: 16px;
  height: 16px;
  margin: 0 auto;
  display: block;
  margin-top: 8px;
  border-radius: 50%;
  background: $daseruno-red;

  &_small {
    @extend .radioButtonCheckMark;
    margin-top: 6px;
    width: 12px;
    height: 12px;
  }
}

.radioButtonInput {
  display: none;
}

.radioButtonText {
  margin-left: 10px;
  font-size: 14px;
  color: $daseruno-gray2;

  &_small {
    @extend .radioButtonText;
    margin-left: 6px;
    font-size: 14px;

    &_bold {
      @extend .radioButtonText_small;
      font-weight: 600;
    }
  }
}

.radioButtonTextWidth {
  width: 130px;

  &_small {
    width: 70px;
  }
}

.addform {
  width: 1002px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  &_form {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    &_center {
      @extend .addform_form;
      align-items: center;
    }
  }

  &_space_box {
    min-width: 157px;
    min-height: 50px;
    border-radius: 4px;
    background: $daseruno-light-gray;
    border: 2px solid $daseruno-light-gray;

    &_icon {
      min-width: 20px;
      min-height: 20px;
    }
  }

  &_error_text {
    font-size: 14px;
    font-weight: 600;
    color: $daseruno-red;
    margin-bottom: 12px;
  }

  &_line {
    margin-left: 144px;
    margin-top: 36px;
    width: 548px;
    border-bottom: 2px solid $daseruno-light-gray;
  }

  &_space {
    margin-left: 144px;
    margin-top: 36px;
    width: 548px;
  }

  &_delete_button {
    margin-bottom: 16px;
  }

  &_delete_button_2 {
    margin-left: 8px;
    margin-right: 320px;
    margin-bottom: 16px;
  }

  &_add_button {
    margin-top: 20px;
  }

  &_add_button_2 {
    margin-left: 186px;
    margin-top: 20px;
  }

  &_pettern_info {
    margin-top: 8px;
    margin-bottom: 8px;

    &_image {
      width: 32px;
      height: 32px;
    }

    &_tool_tip {
      width: 480px;
      min-height: 12px;
      padding: 8px;
      position: absolute;
      font-size: 12px;
      font-weight: 400;
      overflow-wrap: break-word;
      background: $daseruno-light-gray;
      border: 2px solid $daseruno-light-gray;
      border-radius: 4px;
      transform: translateX(58%) translateY(80%);
      z-index: 10;
    }
  }

  &_supplier {
    &_box {
      margin-left: 24px;
      width: 724px;
      height: 1240px;
      border: 2px solid $daseruno-light-gray;
      padding-top: 24px;
      padding-left: 96px;
      padding-bottom: 24px;

      &_check {
        @extend .addform_supplier_box;
        border: 2px solid $daseruno-red;
        background: $daseruno-orange-opacity;
      }
    }
  }

  &_notice_text {
    margin-top: 8px;
    color: $daseruno-red;
    font-size: 12px;
    font-weight: bold;
  }
}

.suggest {
  position: relative;

  &_clear {
    position: absolute;
    left: 612px;
    top: 16px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
}
