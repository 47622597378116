@import 'src/styles/variables.module.scss';

.fileUploadContainer {
  display: flex;
  align-items: center;
}

.fileUploadLabel {
  display: flex;
  width: 220px;
  height: 48px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 4px;
  color: $white;
  text-align: center;
  vertical-align: middle;
  padding: 3px 0;
  background: $daseruno-blue;
}

.fileUploadInput {
  display: none;
}

.fileUploadIcon {
  width: 24px;
  height: 24px;
  margin-left: 28px;
  margin-top: 12px;
  margin-bottom: 12px;
}

.fileUploadText {
  margin-left: 13px;
  margin-top: 13px;
  margin-bottom: 17px;
}

.fileUploadDescription {
  margin-left: 6px;
  font-size: 12px;
  border-radius: 50px;
  color: $daseruno-gray2;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &_with_icon {
    @extend .button;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  // color
  &_orange {
    color: $white;
    background: $daseruno-orange;
    border: 2px solid $daseruno-orange;
  }

  &_orange_outlined {
    color: $daseruno-orange;
    background: $white;
    border: 2px solid $daseruno-orange;
  }

  &_gray {
    color: $white;
    background: $daseruno-gray1;
    border: 2px solid $daseruno-gray1;
  }

  &_light_gray {
    color: $black;
    background: $daseruno-light-gray;
    border: 2px solid $daseruno-light-gray;
  }

  &_white {
    color: $daseruno-gray1;
    background: $white;
    border: 2px solid $white;
  }

  &_white2 {
    color: $daseruno-orange;
    background: $white;
    border: 2px solid $white;
  }

  &_none {
    color: $daseruno-opacity;
    background: $daseruno-opacity;
    border: 2px solid $daseruno-opacity;
  }

  // size
  &_icon {
    width: 20px;
    height: 20px;

    &_unclickable {
      @extend .button_icon;
      cursor: default;
    }
  }

  &_small {
    border-radius: 42px;
    width: 150px;
    height: 54px;
    font-size: 14px;
    font-weight: 700;
  }

  &_xsmall {
    border-radius: 42px;
    width: 156px;
    height: 50px;
    font-size: 16px;
    font-weight: 600;
  }

  &_middle {
    border-radius: 42px;
    width: 184px;
    height: 50px;
    font-size: 16px;
    font-weight: 600;
  }

  &_xmiddle {
    border-radius: 42px;
    width: 240px;
    height: 54px;
    font-size: 14px;
    font-weight: 600;
  }

  &_semilarge {
    border-radius: 42px;
    width: 254px;
    height: 60px;
    font-size: 20px;
    font-weight: 600;
  }

  &_large {
    border-radius: 42px;
    width: 250px;
    height: 76px;
    font-size: 16px;
    font-weight: 600;
  }

  &_xlarge {
    border-radius: 42px;
    width: 280px;
    height: 69px;
    font-size: 16px;
    font-weight: 600;
  }

  &_rectangle_small {
    border-radius: 4px;
    width: 118px;
    height: 48px;
    font-size: 14px;
    font-weight: 600;
  }

  &_rectangle_middle {
    border-radius: 4px;
    width: 146px;
    height: 64px;
    font-size: 14px;
    font-weight: 600;
  }

  // icon画像の大きさ
  &_xlarge_image {
    width: 24px;
    height: 24px;

    &_space {
      width: 32px;
    }

    &_label_box {
      width: 201px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &_small_image {
    width: 32px;
    height: 32px;

    &_space {
      width: 0;
    }

    &_label_box {
      width: 107px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &_large_image {
    width: 20px;
    height: 20px;

    &_space {
      width: 22px;
    }

    &_label_box {
      width: 196px;
      display: flex;
      justify-content: center;
      align-items: center;

      &_right {
        margin-left: 12px;
        justify-content: start;
      }
    }
  }

  &_visible_image {
    width: 20px;
    height: 12px;
  }

  &_invisible_image {
    width: 20px;
    height: 14px;
  }

  &_sort_image {
    width: 12px;
    height: 12px;
  }

  &_label_box {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.mypagelink {
  min-width: 311px;
  min-height: 196px;
  margin-bottom: 24px;
  margin-left: 16px;
  border: 2px solid $daseruno-orange;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  text-decoration: none;
  color: $daseruno-orange;
  background: $white;

  &:hover {
    color: $white;
    background: $daseruno-orange;
  }

  &_center {
    min-width: 100%;

    &_icon {
      @extend .mypagelink_center;
      display: flex;
      justify-content: center;
    }

    &_text {
      @extend .mypagelink_center;
      display: flex;
      justify-content: center;
    }
  }

  &_icon {
    width: 24px;
    height: 24px;
    object-fit: contain;
  }
}
