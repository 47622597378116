@import 'src/styles/variables.module.scss';

.main {
  box-sizing: border-box;
  height: 100%;
  min-width: 310px;
}

.container {
  margin: 0;
  height: 100vh;
  position: fixed;
  z-index: 99;
  background: $white;
  box-shadow: 4px 0 60px rgba(157, 119, 73, 0.2);
}

.containerLogo {
  // margin-top: 28px;
  // margin-bottom: 32px;
  margin-top: 9%;
  margin-bottom: 10%;
  margin-left: 64px;
  margin-right: 64px;
}

.logo {
  max-width: 184px;
  padding: 0;
  width: 100%;
  margin: 0 auto;
  display: block;
}

.containerAccount {
  margin-left: 24px;
  margin-right: 24px;
  // margin-right: 64px;
  // margin-bottom: 8px;
  margin-bottom: 2%;
  display: flex;
  align-items: center;
}

.accountIcon {
  width: 48px;
  height: 48px;
  padding: 0;
  display: block;
  border-radius: 50%;
}

.accountText {
  font-size: 14px;
  font-weight: bold;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: 20px;
  width: min-content;
  flex: 1;
}

.accountSubText {
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  margin-top: 8px;
  margin-bottom: 8px;
}

.menuBox {
  width: 100%;
  display: flex;
  // margin-bottom: 12px;
  margin-bottom: 4%;
  max-height: 36px;
  align-items: center;
  cursor: pointer;
  &:hover {
    color: $daseruno-orange;
  }
}

.selectedMenuBar {
  width: 12px;
  height: 36px;
  border-radius: 2px;
}
// TODO: 擬似クラスに整理
.selectedMenuBarSelected {
  width: 12px;
  height: 36px;
  border-radius: 2px;
  background: $daseruno-orange;
}

.sideMenuIcon {
  margin-left: 24px;
  max-width: 24px;
  max-height: 24px;
  padding: 0;
  width: 100%;
  height: auto;
}

.sideMenuImage {
  margin-left: 20px;
  max-width: 63px;
  max-height: 63px;
  padding: 0;
  width: 100%;
  height: auto;
}

.sideMenuText {
  margin-left: 20px;
  font-size: 14px;
  font-weight: 600;
  margin-top: 4px;
  margin-bottom: 4px;
  color: $daseruno-gray2;
  &:hover {
    color: $daseruno-orange;
  }
}

.sideMenuLink {
  text-decoration: none;
  color: $black;
}

.containerUseMenu {
  // margin-top: 24px;
  // margin-bottom: 40px;
  margin-top: 8%;
  margin-bottom: 12%;
}

.containerExitMenu {
  display: flex;
  flex-direction: row;
}

.shop_change {
  width: 284px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &_clear {
    position: absolute;
    left: 228px;
    top: 20px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
}

.sidemenu_modal {
  position: relative;

  &_box {
    position: absolute;
    left: 269px;
    top: 0;
    width: 272px;
    min-height: 60px;
    border-radius: 24px;
    background: $white;
    box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.1);
    font-weight: 600;
    font-size: 16px;
    z-index: 100; /** modal_price_cardより大きい */
  }

  &_container {
    margin-top: 23px;
    margin-bottom: 23px;
    margin-left: 39px;
    margin-right: 19px;
  }

  &_link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    color: $black;
    padding: 0;

    &:hover {
      color: $daseruno-orange;
    }
  }

  &_arrow {
    margin-top: 2px;
    width: 38px;
    height: 18px;
  }
}
