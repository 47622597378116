@import 'src/styles/variables.module.scss';

.labelFormContainer {
  display: flex;
  align-items: center;
  margin-top: 48px;
}

.labelFormContainerSub {
  display: flex;
  align-items: center;
  margin-top: 24px;
}

.labelFormContainerMin {
  display: flex;
  align-items: center;
  margin-top: 16px;
}

.labelFormTopContainer {
  display: flex;
  align-items: flex-start;
  margin-top: 48px;
  margin-bottom: 48px;
}

.labelContainer {
  width: 192px;
  display: flex;
  align-items: center;

  &_minimum {
    width: 64px;
  }

  &_small {
    @extend .labelContainer;
    width: 156px;
  }

  &_large {
    @extend .labelContainer;
    width: 225px;
  }
}

.lightLabelContainer {
  display: flex;
  align-items: center;

  &_minimum {
    width: 64px;
  }
}

.labelTopContainer {
  margin-top: 4px;
}

.requiredLabel {
  width: 42px;
  height: 18px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 4px;
  color: $white;
  text-align: center;
  vertical-align: middle;
  padding: 3px 0;
  background: $daseruno-red;
  margin-left: 16px;
}

.optionalLabel {
  width: 42px;
  height: 18px;
  margin-left: 16px;
}

.labelText {
  max-width: 99px;
  min-width: 12px;
  margin-left: 17px;
  font-size: 14px;
  font-weight: 600;
  color: $daseruno-gray2;
}

.labelText2 {
  max-width: 80px;
  margin-left: 17px;
  font-size: 14px;
  font-weight: 600;
  color: $daseruno-gray2;
}

.labelText3 {
  max-width: 126px;
  min-width: 12px;
  margin-left: 16px;
  font-size: 14px;
  font-weight: 600;
  color: $daseruno-gray2;
}

.lightLabelText {
  font-size: 14px;
  font-weight: 600;
  color: $daseruno-gray2;
}

.lightLabelInputContainer {
  margin-top: 8px;
  display: flex;
  align-items: center;

  &_center {
    @extend .lightLabelInputContainer;
    justify-content: center;
  }

  &_end {
    @extend .lightLabelInputContainer;
    justify-content: end;
  }

  &_between {
    @extend .lightLabelInputContainer;
    justify-content: space-between;
  }

  &_vertical_end {
    @extend .lightLabelInputContainer;
    align-items: end;
  }

  &_margin_unit {
    margin-right: 24px;
  }
}

.inputToolTipContainer {
  position: relative;
}

.inputToolTipIcon {
  max-width: 16px;
  max-height: 16px;
  position: absolute;
  transform: translateY(-100%);
}

.inputToolTipIconLine2 {
  max-width: 16px;
  max-height: 16px;
  position: absolute;
  transform: translateY(-150%);
}

.inputToolTipIconLine3 {
  max-width: 16px;
  max-height: 16px;
  position: absolute;
  transform: translateY(-230%);
}

.inputToolTip {
  width: 240px;
  min-height: 12px;
  padding: 8px;
  position: absolute;
  font-size: 12px;
  font-weight: 400;
  overflow-wrap: break-word;
  background: $daseruno-light-gray;
  border: 2px solid $daseruno-light-gray;
  border-radius: 4px;
  transform: translateX(60%) translateY(50%);
  z-index: 10;

  &_light {
    @extend .inputToolTip;
    transform: translateX(45%) translateY(50%);
  }
}

.inputAndUnitTextContainer {
  margin-left: 13px;
  font-size: 14px;
  font-weight: 600;
  color: $daseruno-gray2;
  text-align: center;

  &_single_byte {
    @extend .inputAndUnitTextContainer;
    margin-left: 15px;
  }

  &_end {
    @extend .inputAndUnitTextContainer;
    text-align: end;
  }
}

.checkboxContainer {
  width: 430px;
  display: flex;
  flex-wrap: wrap;
}

.checkboxAndLabelContainer {
  width: 94px;
  height: 44px;
}

.checkboxAndLabelContainerLong {
  width: 108px;
  height: 44px;
}

.checkboxAndLabelContainerLong2 {
  width: 143px;
  height: 44px;
}

.checkboxAndLabelContainerLong3 {
  width: 175px;
  height: 44px;
}

.checkboxAndLabelContainerLong4 {
  width: 245px;
  height: 44px;
}

.radioButtonContainer {
  width: 430px;
  display: flex;
  align-items: center;
}

.radio {
  height: 32px;

  &_small {
    @extend .radio;
    width: 96px;
  }

  &_middle {
    @extend .radio;
    width: 122px;
  }

  &_middle2 {
    @extend .radio;
    width: 140px;
  }

  &_large {
    @extend .radio;
    width: 162px;
  }
}

.radioButtonAndnputAndUnitTextContainer {
  width: 430px;
  display: flex;
  align-items: center;
}

.radioButtonComboContainer {
  width: 258px;
  display: flex;
}

.inputAndUnitComboContainer {
  display: flex;
  align-items: center;
}

.status_label {
  width: 80px;
  height: 24px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 4px;
  color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 0;

  &_blue {
    @extend .status_label;
    background: $daseruno-blue;
  }

  &_green {
    @extend .status_label;
    background: $daseruno-green;
  }

  &_red {
    @extend .status_label;
    background: $daseruno-red;
  }

  &_gray {
    @extend .status_label;
    background: $daseruno-gray1;
  }
}

.selectCheckBoxContainer {
  margin-top: 8px;
  position: relative;
}

.selectCheckBoxModal {
  position: absolute;
  width: 670px;
  height: 290px;
  border-radius: 4px;
  border: 2px solid $daseruno-light-gray;
  color: $daseruno-gray2;
  background: $white;
  z-index: 1;

  &_middle {
    @extend .selectCheckBoxModal;
    width: 348px;
  }
}

.selectCheckBoxIconArea {
  margin-top: 8px;
  margin-right: 12px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.selectCheckBoxIcon {
  width: 18px;
  height: 38px;
}

.selectCheckBoxArea {
  position: absolute;
  margin-top: 32px;
  margin-left: 42px;
  margin-right: 42px;
  height: 182px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.inputtext_suggest {
  display: flex;
  align-items: center;
  margin-top: 48px;
  position: relative;

  &_none {
    @extend .inputtext_suggest;
    margin-top: 0px;
  }

  &_light {
    @extend .inputtext_suggest;
    margin-top: 8px;
  }

  &_semi_light {
    @extend .inputtext_suggest;
    margin-top: 16px;
  }

  &_pulldown {
    position: absolute;
    max-width: 670px;
    max-height: 290px;
    border-radius: 4px;
    border: 2px solid $daseruno-light-gray;
    color: $daseruno-gray2;
    background: $white;
    z-index: 1;
  }

  &_text {
    padding: 8px;
    font-size: 12px;
    font-weight: 400;
    &:hover {
      background: rgba(244, 164, 96, 0.2);
      font-weight: 600;
    }
  }
}
