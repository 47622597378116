@import "src/styles/variables.module.scss";

.container {
  margin-top: 57px;
  max-width: 1002px;
  height: 59px;
  border-bottom: 2px solid $daseruno-light-gray;
  display: flex;
  justify-content: space-between;

  &_tab {
    margin-left: 80px;
    width: 572px;
    height: 59px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
}

.tab {
  width: 280px;
  height: 48px;
  background: $daseruno-light-gray;
  border-radius: 8px 8px 0px 0px;
  font-size: 14px;
  color: $daseruno-gray2;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  cursor: pointer;

  &_selected {
    width: 280px;
    height: 59px;
    background: $daseruno-orange;
    border-radius: 8px 8px 0px 0px;
    font-size: 14px;
    font-weight: 600;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
  }
}

.title {
  margin-top: 36px;
  font-size: 20px;
  font-weight: 600;
}
