@import "src/styles/variables.module.scss";

.main {
  background: $white;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
}

.flex {
  display: flex;
}

.content {
  width: 100%;
}

.fullContent {
  width: 100%;
  height: 100%;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
