@import "src/styles/variables.module.scss";

.container {
  display: flex;
  align-items: center;
}

.pageHeddingArrow {
  max-width: 30px;
  max-height: 30px;
  padding: 0;
  width: 100%;
  height: auto;
}

.pageHeddingText {
  height: 30px;
  margin-left: 18px;
  font-size: 18px;
  margin-top: 4px;
  margin-bottom: 4px;
}

.pageHeddingLink {
  text-decoration: none;
  color: $black;
}
