@import "src/styles/variables.module.scss";

.field {
  width: 1002px;
  border-radius: 4px;
  border: 2px solid $daseruno-light-gray;

  &_head {
    height: 59px;
    margin-left: 34px;
    margin-right: 22px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &_text {
      font-weight: 600;
      font-size: 14px;
    }

    &_img {
      width: 32px;
      height: 32px;
    }
  }

  &_content {
    margin-left: 34px;
    margin-right: 69px;
    margin-bottom: 27px;

    &_text {
      font-weight: 400;
      font-size: 14px;
      word-break: all;
    }
  }
}
