@import "src/styles/variables.module.scss";

.previewImageContainer {
  margin: 8px;
  position: relative;
}

.image {
  width: 106px;
  height: 80px;
}

.button {
  border: 0;
  background: none;
}

.icon {
  width: 24px;
  height: 24px;
  position: absolute;
  transform: translateX(420%) translateY(-40%);
}
