@import "src/styles/variables.module.scss";

.container {
  display: flex;
}

.checkImage {
  margin-top: 16px;
  max-width: 32px;
  max-height: 32px;
}

.mainText {
  margin-left: 8px;
  font-size: 18px;
  margin-top: 18px;
  font-weight: bold;
  color: $daseruno-gray2;

  &_red {
    @extend .mainText;
    color: $daseruno-red;
  }
}

.subText {
  margin-left: 8px;
  font-size: 14px;
  margin-top: -12px;
  font-weight: bold;
  color: $daseruno-gray2;

  &_red {
    @extend .subText;
    color: $daseruno-red;
  }
}
