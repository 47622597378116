@import "src/styles/variables.module.scss";

.page_head_attention {
  border-radius: 4px;
  max-width: 1002px;
  height: 128px;
  color: $white;
  background: $daseruno-red;
  border: 2px solid $daseruno-red;

    &_title {
      display: flex;
      font-size: 16px;
      font-weight: 700;

      &_icon {
        margin-top: 15px;
        margin-left: 16px;
        width: 32px;
        height: 32px;
      }

      &_text {
        margin-top: 20px;
        margin-left: 10px;
      }
    }

    &_description {
      margin-left: 58px;
      max-width: 900px;
      font-size: 14px;
      font-weight: 400;
    }
}

.container {
  margin-top: 52px;
  margin-left: 52px;
  margin-right: 52px;
  margin-bottom: 36px;
}

.arrowImage {
  height: 18px;
  vertical-align: middle;
}

.breadCrumbText {
  font-size: 12px;
  color: $daseruno-gray1;
  vertical-align: middle;
}
// TODO: 擬似クラスに整理
.breadCrumbTextBold {
  font-size: 12px;
  font-weight: bold;
  color: $daseruno-gray1;
}

.breadCrumbLink {
  text-decoration: none;
  color: $daseruno-gray1;
  &:hover {
    text-decoration: underline;
  }
}
