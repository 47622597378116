@import "src/styles/variables.module.scss";

.number_box {
  border-radius: 4px;
  max-width: 1002px;
  min-width: 76px;
  height: 76px;
  background: $daseruno-light-gray;
  border: 2px solid $daseruno-light-gray;
  display: flex;

  &_alert {
    @extend .number_box;
    background: $daseruno-red;
    border: 2px solid $daseruno-red;
  }

  &_container {
    margin-left: 6px;
    margin-top: 6px;
    margin-right: 6px;
    margin-bottom: 6px;
    width: 64px;
    height: 64px;
    background: $white;

    &_flex {
      display: flex;
      justify-content: center;
      margin-top: 4px;
    }

    &_alert_image {
      margin-top: 6px;
      margin-left: 16px;
      width: 32px;
      height: 32px;
    }

    &_gray {
      @extend .number_box_container;
      background: $daseruno-light-gray;
    }

    &_red {
      @extend .number_box_container;
      background: $daseruno-red;
    }

    &_label {
      font-size: 12px;
      font-weight: 600;
      color: $daseruno-gray2;
      text-align: center;
      margin-top: 4px;

      &_red {
        @extend .number_box_container_label;
        color: $daseruno-red;
      }

      &_white {
        @extend .number_box_container_label;
        color: $white;
      }
    }

    &_num {
      font-size: 16px;
      font-weight: 700;
      color: $daseruno-gray2;

      &_red {
        @extend .number_box_container_num;
        color: $daseruno-red;
      }

      &_white {
        @extend .number_box_container_num;
        color: $white;
      }
    }

    &_unit {
      font-size: 10px;
      font-weight: 700;
      color: $daseruno-gray2;
      margin-left: 4px;
      margin-top: 4px;

      &_red {
        @extend .number_box_container_unit;
        color: $daseruno-red;
      }
    }

    &_annotation {
      font-size: 8px;
      font-weight: 400;
      color: $daseruno-gray2;
      text-align: center;
      margin-top: 2px;

      &_red {
        @extend .number_box_container_annotation;
        color: $daseruno-red;
      }
    }
  }
}

.allergen_icon_box {
  display: flex;
  align-items: center;
  width: 84px;
  height: 36px;

  &_image {
    width: 36px;
    height: 36px;
  }

  &_image_box {
    width: 36px;
    height: 36px;
    margin-top: -8px;
  }

  &_label {
    width: 48px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $black-opacity;

    &_bold {
      @extend .allergen_icon_box_label;
      font-weight: 700;
      color: $black;
    }
  }
}

.loss_rate_box {
  margin-top: 12px;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 36px;
  background: $daseruno-light-gray;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  color: $daseruno-gray2;

  &_label {
    margin-left: 8px;
    width: 160px;
  }

  &_value {
   margin-right: 8px;
  }
}
