@import "src/styles/variables.module.scss";

.dashboardTableContainer {
  width: 1002px;
  height: 484px;
  border-spacing: 0;
  margin-top: 42px;
  border-radius: 4px;
  border: 2px solid $daseruno-light-gray;
  font-size: 12px;
  font-weight: 600;
  color: $daseruno-gray2;
}

.dashboardTableHeader {
  width: 1002px;
  height: 32px;
  background: $daseruno-light-gray;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > th {
    margin-left: 8px;
    margin-right: 8px;
  }

  &_start {
    margin-left: 8px;
  }

  &_end {
    margin-right: 8px;
  }
}

.dashboardTableBodyRow {
  width: 1002px;
  height: 63px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > td {
    margin-left: 8px;
    margin-right: 8px;
  }

  &_start {
    margin-left: 8px;
  }

  &_end {
    margin-right: 8px;
  }
}

.dashboardTableBodyCol {
  margin-left: 0;
  margin-right: 0;
  position: relative;

  &_check {
    @extend .dashboardTableBodyCol;
    width: 36px;
  }

  &_thumbnail {
    @extend .dashboardTableBodyCol;
    width: 60px;

    &_image {
      width: 56px;
      height: 42px;
    }

    &_opacity {
      @extend .dashboardTableBodyCol_thumbnail;
      opacity: 0.5
    }
  }

  &_link_text {
    color: $black
  }

  &_name {
    @extend .dashboardTableBodyCol;
    width: 177px;
    text-align: left;

    &_opacity {
      @extend .dashboardTableBodyCol_name;
      opacity: 0.5
    }
  }

  &_semi_long_name {
    @extend .dashboardTableBodyCol;
    width: 217px;
    text-align: left;

    &_opacity {
      @extend .dashboardTableBodyCol_semi_long_name;
      opacity: 0.5
    }
  }

  &_semi_long_name2 {
    @extend .dashboardTableBodyCol;
    width: 377px;
    text-align: left;

    &_opacity {
      @extend .dashboardTableBodyCol_semi_long_name2;
      opacity: 0.5
    }
  }

  &_long_name {
    @extend .dashboardTableBodyCol;
    width: 830px;
    text-align: left;

    &_opacity {
      @extend .dashboardTableBodyCol_semi_long_name;
      opacity: 0.5
    }
  }

  &_price {
    @extend .dashboardTableBodyCol;
    width: 84px;
    text-align: right;

    &_red {
      @extend .dashboardTableBodyCol_price;
      color: $daseruno-red
    }

    &_blue {
      @extend .dashboardTableBodyCol_price;
      color: $daseruno-blue
    }

    &_opacity {
      @extend .dashboardTableBodyCol_price;
      opacity: 0.5
    }
  }

  &_form_unit {
    @extend .dashboardTableBodyCol;
    width: 144px;
    text-align: right;
    display: flex;

    &_opacity {
      @extend .dashboardTableBodyCol_form_unit;
      opacity: 0.5
    }
  }

  &_status {
    @extend .dashboardTableBodyCol;
    width: 112px;
    display: flex;
    justify-content: center;
    align-items: center;

    &_opacity {
      @extend .dashboardTableBodyCol_status;
      opacity: 0.5
    }
  }

  &_icon {
    @extend .dashboardTableBodyCol;
    width: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    &_middle {
      @extend .dashboardTableBodyCol_icon;
      width: 32px;
    }

    &_small {
      @extend .dashboardTableBodyCol_icon;
      width: 12px;
    }
  }

  &_no_product_image {
    position: absolute;
    width: 291px;
    height: 114px;
    transform: translateX(140%) translateY(-280%);
  }
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.flex2 {
  display: flex;
  align-items: center;
  text-align: center;
}

.margin_left {
  margin-left: 8px;
}

.margin_top {
  margin-top: 4px;
}

.tooltip {
  min-width: 240px;
  width: 480px;
  min-height: 12px;
  padding: 8px;
  position: absolute;
  font-size: 12px;
  font-weight: 600;
  overflow-wrap: break-word;
  background: $daseruno-red;
  border: 2px solid $daseruno-red;
  border-radius: 4px;
  color: $white;
  transform: translateX(55%) translateY(40%);
  z-index: 90000;
}

.bread_detail {

  &_secondary_container {
    min-height: 48px;
    max-width: 900px;
    margin-right: 48px;
    border: 2px solid $daseruno-orange-opacity2;
    border-radius: 0 4px 4px 4px;
    margin-bottom: 12px;
  }

  &_primary_header {
    display: flex;
    justify-content: flex-end;

    &_gray {
      @extend .bread_detail_primary_header;
      background: $daseruno-light-gray;
    }

    &_orange {
      @extend .bread_detail_primary_header;
      background: $daseruno-orange-opacity2;
    }
  }

  &_open_close_icon {
    width: 17px;
    height: 17px;
    margin-top: 16px;
    margin-left: 16px;
  }

  &_terminal_icon {
    width: 12px;
    height: 12px;
    margin-top: 16px;
    margin-left: 16px;
  }

  &_paragraph_down {
    margin-left: 8px;

    &_large {
      margin-left: 33px;
    }

    &_large2 {
      margin-left: 50px;
    }

    &_large3 {
      margin-left: 12px;
    }

    &_large4 {
      margin-left: 36px;
    }

    &_adjustment {
      margin-left: -12px;
    }
  }

  &_add_button {
    margin-left: 8px;
  }

  &_input_and_unit {
    margin-left: 2px;
  }
}
