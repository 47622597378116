@import "./variables.module.scss";
@import "@fontsource/roboto";


html, body {
  // color: $black;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  // -webkit-font-smoothing: antialiased;
  // font-feature-settings: "palt";
  // letter-spacing: 1px;
  // line-height: 1.5;
  // box-sizing: border-box;
  // -webkit-text-size-adjust: 100%; /* Prevent adjustments of font size after orientation changes in iOS */
  // word-break: normal;
  // -moz-tab-size: 4;
  // tab-size: 4;
}