$sacri-red-light: #fa705c;
$sacri-red: #c23f32;
$sacri-red-dark: #8b000b;
$sacri-orange-light: #edb294;
$sacri-orange: #db6529;
$sacri-gradation: radial-gradient(100% 100% at 0% 0%, #ee8023 0%, #c23f32 100%);

$sacri-blue-light: #6a9eac;
$sacri-blue: #2a7589;
$sacri-blue-dark: #00495c;
$ios-blue: #007aff;

$sacri-yellow-light: #fbf8d8;
$sacri-yellow: #eddb3a;
$sacri-green: #279a40;
$sacri-white: #eeeee3;
$sacri-white-dark: #989884;
$sacri-black: #231815;
$caution-red-light: #f3d9d6;
$caution-red: #ff0303;

$daseruno-orange: #F4A460;
$daseruno-orange-opacity: rgba(244, 164, 96, 0.1);
$daseruno-orange-opacity2: rgba(244, 164, 96, 0.2);
$daseruno-gray1: #8B8B8B;
$daseruno-gray2: #4A5568;
$daseruno-light-orange: #F9EBE3;
$daseruno-light-orange2: #FAF5F1;
$daseruno-light-gray: #E2E8F0;
$daseruno-light-gray-opacity: rgba(226, 232, 240, 0.5);
$daseruno-red: #ed7c7c;
$daseruno-red-opacity: rgba(237, 124, 124, 0.2);
$daseruno-red-opacity2: rgba(237, 124, 124, 0.1);
$daseruno-blue: #56adde;
$daseruno-light-green: #65beb9;
$daseruno-green: #7fba8c;
$daseruno-opacity: rgba(255, 255, 255, 0);

$black: #000;
$black-opacity: rgba(0, 0, 0, 0.4);
$grey01: #6a6a6a;
$grey02: #9a9a9a;
$grey03: #bababa;
$grey04: #dadada;
$grey05: #f6f7f8;
$white: #fff;
$modal-white: #fdfdfd;
$modal-black: #1a1a1a;
$ghost: #bababa 70%;
$nav-bar: #1a1a1a;
$shadow: #e0e1e3;

$breakpoints: (
  'xs': 'screen and (min-width: 440px)',
  'sm': 'screen and (min-width: 600px)',
  'md': 'screen and (min-width: 768px)',
  'lg': 'screen and (min-width: 1024px)',
  'xl': 'screen and (min-width: 1440px)',
) !default;

// 初期値はlg(tablet screen size)
@mixin mq($breakpoint: lg) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

// プレイスホルダーの色指定
@mixin placeholder($color) {
  &::placeholder {
    color: $color !important;
  }

  // IE
  &:-ms-input-placeholder {
    color: $color !important;
  }

  // Edge
  &::-ms-input-placeholder {
    color: $color !important;
  }
}
